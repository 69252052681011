<template>
    <v-card>
        <v-container>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col md="12" lg="12">
                        <v-card-title
                            class="
                                pa-3
                                text-center
                                grey--text
                                font-weight-black
                            "
                            :style="
                                isExtraSmall
                                    ? 'display: flex; justify-content: center;'
                                    : ''
                            "
                        >
                            <v-icon v-if="!isExtraSmall" large class="mx-3">
                                mdi-chevron-down
                            </v-icon>
                            Criar novo usuário
                        </v-card-title>
                        <v-row :class="!isExtraSmall ? 'alinhar-centro' : ''">
                            <v-col cols="12" sm="8" md="8">
                                <v-card-text
                                    :class="!isExtraSmall ? 'mx-3' : 'py-0'"
                                >
                                    Informe os detalhes do usuário que será
                                    criado, suas permisões e selecione o tipo de
                                    usuário
                                </v-card-text>
                            </v-col>
                            <v-col
                                class="d-flex pr-6"
                                :class="
                                    isExtraSmall
                                        ? 'justify-center pt-0'
                                        : 'justify-end'
                                "
                                cols="12"
                                sm="4"
                                md="4"
                            >
                                <v-tooltip top>
                                    <template
                                        v-slot:activator="{
                                            on,
                                            attrs
                                        }"
                                    >
                                        <v-btn
                                            color="primary white--text"
                                            @click="modalPermissoes = true"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            Ver Permissões
                                        </v-btn>
                                    </template>
                                    <span>
                                        Clique para visualizar com mais detalhes
                                        as permissões de cada Tipo de Usuário.
                                    </span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                    :class="isExtraSmall ? 'pb-0' : ''"
                                >
                                    <v-text-field
                                        solo
                                        :prepend-icon="
                                            !isExtraSmall
                                                ? 'mdi-text-account'
                                                : ''
                                        "
                                        name="Nome"
                                        label="Nome"
                                        type="text"
                                        counter
                                        maxlength="45"
                                        hint="Tamanho máximo 45 (não permite números e acentos)"
                                        v-model="usuario.name"
                                        :rules="nameRulesConfirmation"
                                        required
                                        data-cy="usuarioNome"
                                    ></v-text-field>
                                </v-col>
                                <v-row class="px-3 pt-3 pb-0">
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        :class="isExtraSmall ? 'py-0' : ''"
                                    >
                                        <v-text-field
                                            v-if="isSuperAdminRecuperi"
                                            solo
                                            :prepend-icon="
                                                !isExtraSmall
                                                    ? 'mdi-account-circle'
                                                    : ''
                                            "
                                            name="login"
                                            label="Login"
                                            type="text"
                                            v-model="usuario.ds_login"
                                            :rules="loginRulesAdminConfirmation"
                                            required
                                            autocomplete="off"
                                            data-cy="usuarioLogin"
                                            @blur="
                                                verificarPreenchimentoLoginAdmin()
                                            "
                                        ></v-text-field>
                                        <v-text-field
                                            v-else
                                            solo
                                            :prepend-icon="
                                                !isExtraSmall
                                                    ? 'mdi-account-circle'
                                                    : ''
                                            "
                                            name="login"
                                            label="Login"
                                            type="text"
                                            v-model="usuario.ds_login"
                                            :rules="loginRulesConfirmation"
                                            required
                                            autocomplete="off"
                                            data-cy="usuarioLogin"
                                            @blur="
                                                verificarPreenchimentoLogin()
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="12"
                                        :class="isExtraSmall ? 'py-0' : ''"
                                    >
                                        <v-text-field
                                            solo
                                            :prepend-icon="
                                                !isExtraSmall ? 'email' : ''
                                            "
                                            name="email"
                                            label="Email"
                                            type="text"
                                            v-model="usuario.ds_email"
                                            :rules="emailRulesConfirmation"
                                            required
                                            autocomplete="off"
                                            data-cy="usuarioEmail"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="6"
                                        :class="isExtraSmall ? 'py-0' : ''"
                                    >
                                        <v-select
                                            solo
                                            color="success"
                                            :items="tpUsuario"
                                            v-model="usuario.tp_usuario"
                                            label="Tipo de usuário"
                                            :rules="userTypeRulesConfirmation"
                                            :prepend-icon="
                                                !isExtraSmall
                                                    ? 'mdi-chevron-right'
                                                    : ''
                                            "
                                            required
                                            data-cy="usuarioTipo"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                        lg="6"
                                        :class="isExtraSmall ? 'py-0' : ''"
                                    >
                                        <v-select
                                            solo
                                            :disabled="
                                                loadingAgrupadores ||
                                                    usuarioAgrupador != null
                                            "
                                            :loading="loadingAgrupadores"
                                            :items="agrupadores"
                                            v-model="usuario.ds_agrupador"
                                            label="Agrupador"
                                            :prepend-icon="
                                                !isExtraSmall
                                                    ? 'mdi-chevron-right'
                                                    : ''
                                            "
                                            item-text="nm_agrupador"
                                            item-value="ds_agrupador"
                                            data-cy="usuarioAgrupador"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-row
                                                    class="
                                                d-flex
                                                justify-center
                                            "
                                                >
                                                    <v-switch
                                                        outlined
                                                        color="success"
                                                        v-model="
                                                            usuario.st_ativo
                                                        "
                                                        label="Está ativo?"
                                                        required
                                                        data-cy="usuarioAtivo"
                                                    ></v-switch>
                                                    <v-btn
                                                        v-if="!isExtraSmall"
                                                        elevation="0"
                                                        class="ma-2"
                                                        small
                                                        fab
                                                    >
                                                        <v-icon
                                                            color="grey lighten-2"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            help
                                                        </v-icon>
                                                    </v-btn>
                                                </v-row>
                                            </template>
                                            <span
                                                >Selecione essa opção para
                                                definir se o usuário está ativo.
                                                Caso o usuário estiver inativo,
                                                não irá conseguir acessar o
                                                sistema.
                                            </span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-row
                                                    class="
                                                        d-flex
                                                        justify-center
                                                    "
                                                >
                                                    <v-switch
                                                        outlined
                                                        color="success"
                                                        v-model="
                                                            usuario.tp_perfil
                                                        "
                                                        value="A"
                                                        label="Usuario de API"
                                                        required
                                                        data-cy="usuarioAPI"
                                                    ></v-switch>
                                                    <v-btn
                                                        v-if="!isExtraSmall"
                                                        elevation="0"
                                                        class="ma-2"
                                                        small
                                                        fab
                                                    >
                                                        <v-icon
                                                            color="grey lighten-2"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            help
                                                        </v-icon>
                                                    </v-btn>
                                                </v-row>
                                            </template>
                                            <span>
                                                Usuários de API são usados
                                                exclusivamente para integrações.
                                                Selecione essa opção para
                                                definir se o usuário é de API.
                                            </span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-row
                                                    class="
                                                        d-flex
                                                        justify-center
                                                    "
                                                >
                                                    <v-switch
                                                        outlined
                                                        color="success"
                                                        v-model="
                                                            usuario.is_lite_readonly
                                                        "
                                                        :disabled="
                                                            !isUsuarioLite
                                                        "
                                                        label="Apenas Leitura"
                                                        required
                                                        data-cy="usuarioReadonly"
                                                    ></v-switch>
                                                    <v-btn
                                                        v-if="!isExtraSmall"
                                                        elevation="0"
                                                        class="ma-2"
                                                        small
                                                        fab
                                                    >
                                                        <v-icon
                                                            color="grey lighten-2"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            help
                                                        </v-icon>
                                                    </v-btn>
                                                </v-row>
                                            </template>
                                            <span
                                                >Opção exclusiva para Usuários
                                                Lite. Selecione esta opção para
                                                definir se o Usuário será apenas
                                                de consulta. Isso implica que
                                                este usuário não poderá emitir
                                                comandos para títulos, podendo
                                                apenas visualizá-los.
                                            </span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" class="pa-6">
                                <v-card
                                    v-if="inserirSenha"
                                    min-height="100%"
                                    class="grey lighten-4 text-center"
                                    style="display: grid; padding: 24px 24px 8px 24px;"
                                >
                                    <div
                                        style="display: flex;
                                            padding-bottom: 16px;
                                            justify-content: space-between;
                                            align-items: center;
                                        "
                                    >
                                        <v-card-text
                                            class="text-left"
                                            :class="!isExtraSmall ? 'pa-3' : ''"
                                        >
                                            Informe abaixo a nova senha para
                                            esse usuário:
                                        </v-card-text>
                                        <div
                                            class="d-flex justify-center align-center"
                                        >
                                            <v-tooltip top>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs
                                                    }"
                                                >
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        class="mr-3 white--text"
                                                        color="primary"
                                                        x-small
                                                        fab
                                                        @click="
                                                            gerarSenhaAutomatica()
                                                        "
                                                        ><v-icon
                                                            >mdi-key</v-icon
                                                        ></v-btn
                                                    >
                                                </template>
                                                <span>
                                                    Gerar senha automaticamente
                                                    para o novo usuário
                                                </span>
                                            </v-tooltip>
                                            <v-btn
                                                x-small
                                                fab
                                                :disabled="
                                                    validarDisableCriarSenha
                                                "
                                                @click="inserirSenha = false"
                                                ><v-icon
                                                    >mdi-close</v-icon
                                                ></v-btn
                                            >
                                        </div>
                                    </div>
                                    <v-text-field
                                        solo
                                        :prepend-icon="
                                            !isExtraSmall ? 'lock' : ''
                                        "
                                        autocomplete="new-password"
                                        :value="userPassword"
                                        v-model="usuario.password"
                                        label="Crie uma senha"
                                        hint="Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)"
                                        :append-icon="
                                            mostrarSenha
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off'
                                        "
                                        @click:append="
                                            mostrarSenha = !mostrarSenha
                                        "
                                        @input="
                                            $refs.passwordConfirmation.validate()
                                        "
                                        @keyup.native.tab="
                                            $refs.passwordConfirmation.focus()
                                        "
                                        :type="
                                            mostrarSenha ? 'text' : 'password'
                                        "
                                        :rules="passwordRules"
                                        data-cy="usuarioPwd"
                                    >
                                        <template v-slot:progress>
                                            <v-progress-linear
                                                v-if="custom"
                                                :value="progress"
                                                absolute
                                                height="7"
                                            ></v-progress-linear> </template
                                    ></v-text-field>
                                    <v-text-field
                                        ref="passwordConfirmation"
                                        solo
                                        :prepend-icon="
                                            !isExtraSmall ? 'lock' : ''
                                        "
                                        name="new-passaword"
                                        label="Repita a nova senha"
                                        :type="
                                            mostrarSenha ? 'text' : 'password'
                                        "
                                        :append-icon="
                                            mostrarSenha
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off'
                                        "
                                        @click:append="
                                            mostrarSenha = !mostrarSenha
                                        "
                                        v-model="usuario.passwordConfirmation"
                                        :rules="passwordRulesConfirmation"
                                        required
                                        data-cy="usuarioPwdConfirm"
                                    ></v-text-field>
                                </v-card>
                                <v-card
                                    v-else
                                    min-height="100%"
                                    class="grey lighten-4 text-center d-flex align-center justify-center"
                                >
                                    <div style="display: grid">
                                        <v-icon
                                            class="ma-3"
                                            elevation="4"
                                            size="120"
                                            color="grey lighten-4--text"
                                            >mdi-lock</v-icon
                                        >
                                        <v-btn
                                            class="
                                                green
                                                white--text
                                                ma-3
                                                pa-3
                                            "
                                            data-cy="liberarInserirSenha"
                                            @click="inserirSenha = true"
                                            ><v-icon>mdi-lock-reset</v-icon
                                            >{{
                                                isExtraSmall
                                                    ? 'Alterar Senha'
                                                    : 'Criar Senha do Usuário'
                                            }}</v-btn
                                        >
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-dialog
                        v-model="modalPermissoes"
                        max-width="60vw"
                        max-height="60vh"
                    >
                        <v-toolbar flat dark color="primary">
                            <v-btn icon dark @click="modalPermissoes = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-col cols="12" sm="12" align="left">
                                <v-toolbar-title>
                                    Permissões de Usuário
                                </v-toolbar-title>
                            </v-col>
                        </v-toolbar>
                        <v-card class="py-4 px-8" style="border-radius: 0">
                            <v-card-text
                                class="mx-0 mt-0 mb-3 pa-0"
                                style="font-weight: 500;"
                            >
                                Confira na tabela abaixo os detalhes das
                                permissões para cada usuário:
                            </v-card-text>
                            <v-card>
                                <v-data-table
                                    :headers="headers"
                                    :items="itensPermissoes"
                                    show-group-by
                                    :footer-props="{
                                        itemsPerPageOptions: [10, 50, 100],
                                        itemsPerPageText: 'Itens por página:'
                                    }"
                                    group-by="category"
                                    height="60vh"
                                >
                                    <template
                                        v-slot:[`item.admin`]="{
                                            item
                                        }"
                                    >
                                        <v-chip :color="getColor(item.admin)">
                                            <v-icon>{{
                                                getBolean(item.admin)
                                            }}</v-icon>
                                        </v-chip>
                                    </template>
                                    <template
                                        v-slot:[`item.basic`]="{
                                            item
                                        }"
                                    >
                                        <v-chip :color="getColor(item.basic)">
                                            <v-icon>{{
                                                getBolean(item.basic)
                                            }}</v-icon>
                                        </v-chip>
                                    </template>
                                    <template
                                        v-slot:[`item.lite`]="{
                                            item
                                        }"
                                    >
                                        <v-chip :color="getColor(item.lite)">
                                            <v-icon>{{
                                                getBolean(item.lite)
                                            }}</v-icon>
                                        </v-chip>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-form>
        </v-container>
    </v-card>
</template>

<script>
import permissoes from '@/helpers/permissoesUsuarios';
import {
    validarDsLogin,
    validarDsLoginAdmin,
    validarEmail,
    validarNome,
    validarSenha,
    validarStringSemEspaco,
    validarAgrupador,
    listarAgrupadores,
    corrigirDsLoginAdmin,
    corrigirDsLogin
} from '@/helpers/Utilitarios';
import { gerarSenha } from '@/helpers/GeradorSenha';

export default {
    name: 'CriarUsuario',
    props: {
        usuario: {
            type: Object,
            required: false
        },
        validarForm: {
            type: Boolean,
            required: false
        },
        mostrarConfirmacao: {
            type: Boolean,
            required: false
        },
        showActionSalvar: Boolean
    },
    data() {
        return {
            isUserApi: false,
            headers: [
                {
                    text: 'Funcionalidade',
                    align: 'start',
                    sortable: false,
                    value: 'funcionalidade',
                    groupable: false
                },
                {
                    text: 'Admin',
                    align: 'start',
                    sortable: false,
                    value: 'admin',
                    groupable: false
                },
                { text: 'Basic', value: 'basic', groupable: false },
                { text: 'Lite', value: 'lite', groupable: false }
            ],
            inserirSenha: false,
            isExtraSmall: false,
            itensPermissoes: permissoes,
            tpUsuario: ['admin', 'basic', 'lite'],
            modalPermissoes: false,
            mostrarSenha: false,
            custom: true,
            value: '',
            userPassword: '',
            errorMessages: null,
            usuarios: {
                id_user: '',
                ds_login: '',
                st_ativo: false,
                name: '',
                password: '',
                email: ''
            },
            rules: {
                required: value => !!value || 'Required.',
                password: value => {
                    const pattern =
                        // eslint-disable-next-line no-useless-escape
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
                    return (
                        pattern.test(value) ||
                        'Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)'
                    );
                }
            },
            items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
            id_user: '',
            ds_login: '',
            st_ativo: false,
            enabled: false,
            password: '',
            email: '',
            valid: null,
            createPassword: '',
            passwordConfirmation: '',
            emailConfirmation: '',
            passwordRules: [
                v =>
                    !v ||
                    this.validarSenha(v) ||
                    'Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)',
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            passwordRulesConfirmation: [
                v =>
                    this.validarConfirmacaoSenha(v) ||
                    'Confirmação de Senha requerida',
                v =>
                    !v ||
                    v === this.usuario.password ||
                    'As senhas devem ser iguais',
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            loginRulesConfirmation: [
                v => !!v || 'Login é requerido',
                v =>
                    this.validarDsLogin(v, this.cdApresentante) ||
                    `Preencha o login em formato válido: usuario@${this.cdApresentante}`,
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            loginRulesAdminConfirmation: [
                v => !!v || 'Login é requerido',
                v =>
                    this.validarDsLoginAdmin(v, this.cdApresentante) ||
                    `Preencha o login em formato válido: usuario@${this.cdApresentante}`,
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            emailRulesConfirmation: [
                v => !!v || 'E-mail é requerido',
                v =>
                    this.validarEmail(v) ||
                    'Preencha o e-mail em formato válido: exemplo@exemplo.com',
                v =>
                    this.validarStringSemEspaco(v) ||
                    'Não é permitido utilizar espaços.'
            ],
            nameRulesConfirmation: [
                v => !!v || 'Nome é requerido',
                v =>
                    this.validarNome(v) ||
                    'Tamanho máximo 45 (não permite números e acentos)'
            ],
            userTypeRulesConfirmation: [
                v => !!v || 'Tipo de Usuário é requerido'
            ],
            agrupadorRulesConfirmation: [
                v =>
                    this.validarAgrupador(v) ||
                    'Tamanho deve ser 3, sem espaços/caracteres'
            ],
            loadingAgrupadores: false
        };
    },
    methods: {
        validarDsLogin,
        validarDsLoginAdmin,
        validarEmail,
        validarNome,
        validarSenha,
        validarStringSemEspaco,
        validarAgrupador,
        listarAgrupadores,
        gerarSenha,
        corrigirDsLogin,
        corrigirDsLoginAdmin,
        gerarSenhaAutomatica() {
            let novaSenha = this.gerarSenha();

            this.usuario.password = novaSenha;
            this.usuario.passwordConfirmation = novaSenha;

            this.mostrarSenha = true;
            this.$forceUpdate();
        },
        validarConfirmacaoSenha(passwordConfirmation) {
            if (
                this.usuario.password &&
                this.usuario.password !== '' &&
                (!passwordConfirmation || passwordConfirmation === '')
            ) {
                return false;
            }
            return true;
        },
        verificarPreenchimentoLoginAdmin() {
            const loginCorrigido = this.corrigirDsLoginAdmin(
                this.usuario.ds_login,
                this.cdApresentante
            );
            this.usuario.ds_login = loginCorrigido;
        },
        verificarPreenchimentoLogin() {
            const loginCorrigido = this.corrigirDsLogin(
                this.usuario.ds_login,
                this.cdApresentante
            );

            if (this.validarDsLogin(loginCorrigido, this.cdApresentante)) {
                this.usuario.ds_login = loginCorrigido;
            }
        },
        getBolean(valor) {
            if (valor === true) {
                return 'mdi-check';
            } else {
                return 'mdi-close';
            }
        },
        getColor(valor) {
            if (valor === true) {
                return 'success';
            } else {
                return 'error';
            }
        },
        handleBreakpoints(val) {
            this.isExtraSmall = false;
            if (val.xs) {
                this.isExtraSmall = true;
            }
        },
        keyupListener(e) {
            if (!e) e = window.event;
            const keyCode = e.code || e.key;

            if (keyCode === 'Escape') {
                this.$emit('fecharModal');
            } else if (keyCode === 'Enter' || keyCode === 'NumpadEnter') {
                this.validarSalvar();
            }
        },
        validarSalvar() {
            if (this.valid) {
                this.$emit('salvarUsuario');
            } else {
                this.$refs.form.validate();
            }
        },
        async checarAgrupadores() {
            try {
                this.loadingAgrupadores = true;
                await this.listarAgrupadores(this.$store, true);
            } catch (e) {
                console.log(e);
                // TODO chamar snackbar
            } finally {
                this.loadingAgrupadores = false;
            }
        }
    },
    watch: {
        breakpoint: {
            deep: true,
            handler(val) {
                this.handleBreakpoints(val);
            }
        },
        modalPermissoes(val) {
            if (val) {
                document.removeEventListener('keyup', this.keyupListener);
            } else {
                setTimeout(() => {
                    document.addEventListener('keyup', this.keyupListener);
                }, 150);
            }
        },
        valid(val) {
            this.$emit('setIsInvalid', !val);
        },
        validarForm(val) {
            if (val) {
                this.$refs.form.validate();
                this.$emit('formValidado', false);
            }
        },
        mostrarConfirmacao(val) {
            if (val) {
                document.removeEventListener('keyup', this.keyupListener);
            } else {
                setTimeout(() => {
                    document.addEventListener('keyup', this.keyupListener);
                }, 150);
            }
        },
        usuario: {
            deep: true,
            handler(val) {
                if (val.tp_usuario !== 'lite') {
                    this.usuario.is_lite_readonly = false;
                }
            }
        }
    },
    computed: {
        isSuperAdminRecuperi() {
            return this.$store.getters.isSuperAdminRecuperi;
        },
        progress() {
            return Math.min(100, this.usuario.password.length * 12);
        },
        breakpoint() {
            return this.$vuetify.breakpoint;
        },
        isUsuarioLite() {
            return this.usuario.tp_usuario === 'lite';
        },
        agrupadores() {
            return this.$store.getters.listaAgrupadores;
        },
        usuarioAgrupador() {
            return this.$store.getters.agrupadorUsuario;
        },
        validarDisableCriarSenha() {
            if (
                (this.usuario.password && this.usuario.password !== '') ||
                (this.usuario.passwordConfirmation &&
                    this.usuario.passwordConfirmation !== '')
            ) {
                return true;
            }
            return false;
        },
        cdApresentante() {
            return this.$store.getters.cdApresentante;
        }
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.keyupListener);
    },
    async mounted() {
        document.addEventListener('keyup', this.keyupListener);
        this.handleBreakpoints(this.breakpoint);

        if (this.usuarioAgrupador) {
            this.usuario.ds_agrupador = this.usuarioAgrupador;
        }

        await this.checarAgrupadores();
    }
};
</script>

<style scoped>
.alinhar-centro {
    display: flex;
    align-items: center;
}
</style>
