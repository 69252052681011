<template>
    <div>
        <ConfirmacaoModal
            v-if="mostrarConfirmacao"
            :showDialog="mostrarConfirmacao"
            :tituloModal="'Usuário sem senha'"
            :textoModal="
                'Ao criar um usuário sem definir uma senha, será atribuída a senha padrão <b>Troqueme@1</b> e o novo usuário deverá redefinir sua senha no primeiro acesso. <br /><br /><b>Importante:</b> é preciso que o e-mail informado seja válido para a redefinição da senha. <br /><br />Deseja prosseguir?'
            "
            :btnConfirmarEmit="'salvarUsuario'"
            @salvarUsuario="salvarUsuario"
            @fecharModal="mostrarConfirmacao = false"
        />
        <!-- Cabeçalho -->
        <div align="center" justify="center" class="pa-3">
            <v-card align="left" justify="left" class="mb-3">
                <v-card-text class="pa-3 font-weight-light white black--text">
                    <v-icon class="primary--text lighten-2 pa-2">
                        mdi-application-edit-outline </v-icon
                    >Usuários > Gerenciar Usuários do sistema
                </v-card-text>
            </v-card>
            <v-card @keyup.native.enter="aplicarFiltros" class="pa-3 mb-4">
                <v-row class="pa-3">
                    <v-col
                        cols="6"
                        xs="6"
                        sm="6"
                        md="4"
                        lg="4"
                        class="d-flex flex-row pa-0"
                    >
                        <v-text-field
                            class="pa-0 ma-0"
                            name="filtro"
                            outlined
                            label="Filtro"
                            type="text"
                            hide-details
                            dense
                            :disabled="loading"
                            v-model="filtroUsuario"
                            data-cy="filtroUsuario"
                        />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col
                        cols="6"
                        xs="6"
                        sm="6"
                        md="4"
                        lg="4"
                        class="d-flex justify-end align-center pa-0"
                    >
                        <v-btn
                            color="green white--text"
                            @click="criarUsuario"
                            data-cy="criarUsuario"
                        >
                            Criar novo usuário
                            <v-icon class="ml-2">add</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <!-- fim do cabeçalho -->
            <!-- Inicio tabela resultados -->
            <Snackbar
                v-if="snackbar"
                :mostrarSnackbar="snackbar"
                :corSnackbar="snackbarColor"
                :mensagemSnackbar="mensagem"
                @fecharSnackbar="snackbar = false"
            />
            <v-data-table
                :headers="dynamicHeaders"
                :items="usuarios"
                :loading="loading"
                loading-text="Carregando Usuários..."
                item-key="usuarios"
                class="elevation-1 pa-3"
                :class="isExtraSmall ? 'alinhar-botao-editar' : ''"
                :footer-props="{
                    itemsPerPageOptions: [10, 20, 50, -1],
                    itemsPerPageText: 'Usuários por página:'
                }"
                data-cy="tabelaUsuarios"
                :search="filtroUsuario"
            >
                <template v-slot:[`item.st_ativo`]="{ item }">
                    <v-switch
                        :color="item.st_ativo != '' ? 'green' : 'red'"
                        readonly
                        v-model="item.st_ativo"
                        inset
                        :style="{
                            color: item.st_ativo != '' ? 'green' : 'red'
                        }"
                    >
                    </v-switch>
                </template>
                <!-- <template v-slot:[`item.is_admin`]="{ item }">
                    <v-btn fab small elevation="0">
                        <v-icon
                            :style="{
                                color: item.is_admin != '' ? 'green' : 'red'
                            }"
                            >{{ getBolean(item.is_admin) }}</v-icon
                        >
                    </v-btn>
                </template> -->
                <template v-slot:[`item.tp_usuario`]="{ item }">
                    {{
                        item.is_lite_readonly
                            ? `${item.tp_usuario} (apenas leitura)`
                            : item.tp_usuario
                    }}
                </template>
                <template slot="item.historico" slot-scope="props">
                    <v-btn
                        fab
                        dark
                        small
                        @click="exibirHistorico(props.item)"
                        class="secondary white--text pa-0"
                        :loading="dialogLoading"
                        :data-cy="`mostrarHistorico${props.index}`"
                    >
                        <v-icon dark>
                            mdi-account-details
                        </v-icon>
                    </v-btn>
                </template>
                <template slot="item.tp_perfil" slot-scope="props">
                    <v-btn
                        icon
                        v-if="props.item.tp_perfil === 'API'"
                        class="secondary white--text"
                    >
                        <v-icon dark>
                            mdi-api
                        </v-icon>
                    </v-btn>
                </template>
                <template slot="item.action" slot-scope="props">
                    <v-btn
                        elevation="0"
                        width="90%"
                        @click="exibirDetalhe(props.item)"
                        :class="!isExtraSmall ? 'mr-2' : 'mb-2 mr-3'"
                        class="grey white--text"
                        :loading="dialogLoading"
                        :data-cy="`editarUsuario${props.index}`"
                    >
                        <v-icon
                            medium
                            class="mr-2"
                            @click="exibirDetalhe(props.item)"
                            :style="{ color: 'white' }"
                            >edit</v-icon
                        >Editar
                    </v-btn>
                </template>
            </v-data-table>
            <!-- Fim tabela resultados -->
        </div>
        <HistoricoUsuarioModal
            v-if="mostrarHistorico"
            :showDialog="mostrarHistorico"
            :historicos="usuarioHistoricos"
            :usuario="usuarioHistorico"
            @fecharModal="mostrarHistorico = false"
        />

        <v-dialog
            v-if="dialog"
            v-model="dialog"
            max-width="90%"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Tela Criar usuário -->
            <Snackbar
                v-if="snackbar"
                :mostrarSnackbar="snackbar"
                :corSnackbar="snackbarColor"
                :mensagemSnackbar="mensagem"
                @fecharSnackbar="snackbar = false"
            />
            <v-card class="ml-1">
                <v-card-title class="pa-0">
                    <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Criar Usuário</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-4">
                    <CriarUsuario
                        :usuario="usuario"
                        :validarForm="validarForm"
                        :mostrarConfirmacao="mostrarConfirmacao"
                        @setIsInvalid="isInvalid = $event"
                        @formValidado="validarForm = $event"
                        @salvarUsuario="salvarUsuario"
                        @fecharModal="closeDialog"
                    />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions
                    :style="
                        !isExtraSmall
                            ? 'display: flex; justify-content: end'
                            : 'display: flex; justify-content: center'
                    "
                >
                    <v-btn
                        class="ml-5"
                        :width="isExtraSmall ? '100' : '120'"
                        color="green white--text"
                        :loading="loadingButtonModal"
                        @click="verificaUsuario"
                        data-cy="salvarUsuario"
                    >
                        Salvar
                    </v-btn>
                    <v-btn
                        class="ml-5"
                        dense
                        color="error"
                        :width="isExtraSmall ? '100' : '120'"
                        @click="closeDialog"
                    >
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
            <!-- fim da tela criar usuário --------------------- -->
        </v-dialog>

        <!-- Tela para editar usuário -->
        <v-dialog
            v-if="dialogEditar"
            v-model="dialogEditar"
            :max-width="superUsuario ? '1200px' : '600px'"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="closeDialogEditar">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Editar Usuário</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-4">
                    <EditarUsuario
                        :usuario="usuario"
                        :validarForm="validarForm"
                        @setIsInvalid="isInvalid = $event"
                        @formValidado="validarForm = $event"
                        @salvarUsuario="salvarUsuario"
                        @fecharModal="closeDialogEditar"
                    />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions
                    :style="
                        !isExtraSmall
                            ? 'display: flex; justify-content: end'
                            : 'display: flex; justify-content: center'
                    "
                >
                    <v-btn
                        class="ml-5"
                        :width="isExtraSmall ? '100' : '120'"
                        color="green white--text"
                        :loading="loadingButtonModal"
                        @click="verificaUsuario"
                        data-cy="salvarUsuarioEditado"
                    >
                        Salvar
                    </v-btn>
                    <v-btn
                        class="ml-5"
                        :width="isExtraSmall ? '100' : '120'"
                        dense
                        color="error"
                        @click="closeDialogUsuario"
                    >
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- fim da tela para editar usuário --------------------- -->
    </div>
</template>

<style scoped>
::v-deep .alinhar-botao-editar td:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
</style>

<script>
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import * as Formatter from '@/helpers/Formatter';
import { getMensagemRequest } from '@/helpers/Utilitarios';
import UsuarioService from '@/services/UsuarioService';
import Usuario from '@/domains/Usuarios';
import CriarUsuario from '@/components/CriarUsuario';
import EditarUsuario from '@/components/EditarUsuario';
import { required, email, max } from 'vee-validate/dist/rules';
import { extend, setInteractionMode } from 'vee-validate';
import ConfirmacaoModal from '@/modals/ConfirmacaoModal';
import { cloneDeep } from 'lodash';
import HistoricoUsuarioModal from '@/modals/HistoricoUsuarioModal';

setInteractionMode('eager');

extend('required', {
    ...required,
    message: '{_field_} can not be empty'
});

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters'
});

extend('email', {
    ...email,
    message: 'Email must be valid'
});

export default {
    name: 'ListaUsuarios',
    mixins: [validationMixin],
    components: {
        CriarUsuario,
        EditarUsuario,
        ConfirmacaoModal,
        HistoricoUsuarioModal
    },
    validations: {
        checkbox: {
            checked(val) {
                return val;
            }
        }
    },
    data() {
        return {
            filtroUsuario: '',
            loadingButtonModal: false,
            mostrarConfirmacao: false,
            mostrarHistorico: false,
            usuarioHistoricos: [],
            isInvalid: false,
            validarForm: false,
            isExtraSmall: false,
            isSmall: false,
            isMedium: false,
            usuarios: [],
            filtros: {
                id: null,
                cartao: false,
                boleto: false,
                status: false,
                data_inicio: this.computedDateFormattedInicio,
                data_fim: this.computedDateFormattedFim,
                data_inicio_fmt: null,
                data_fim_fmt: null,
                valor_inicial: null,
                valor_final: null
            },
            superUsuario: false,
            isCriarUsuario: false,
            loading: false,
            dialog: false,
            dialogEditar: false,
            dialogLoading: false,
            loadingFiltros: null,
            data_inicio: null,
            data_fim: null,
            menu1: false,
            menu2: false,
            modal: false,
            modal2: false,
            cartao: true,
            boleto: true,
            status: true,
            id: null,
            ultimoFiltro: null,
            pagina: 1,
            showPagination: false,
            usuario: new Usuario(),
            snackbar: false,
            primaryColorFont: '#757575',
            snackbarColor: 'green white--text',
            mensagem: null,
            headers: [
                {
                    text: 'Nome',
                    sortable: false,
                    value: 'name'
                },
                {
                    text: 'Usuário',
                    sortable: false,
                    value: 'ds_login'
                },
                {
                    text: 'Ativo',
                    sortable: false,
                    value: 'st_ativo'
                },
                {
                    text: 'Tipo de Usuário',
                    sortable: false,
                    value: 'tp_usuario'
                },
                {
                    text: 'Histórico',
                    sortable: false,
                    align: 'center',
                    value: 'historico'
                },
                {
                    text: 'Usuário de API',
                    sortable: false,
                    align: 'center',
                    value: 'tp_perfil'
                },
                { text: ' ', sortable: false, value: 'action' }
            ]
        };
    },
    methods: {
        getMensagemRequest,
        listarUsuarios() {
            return new Promise((resolve, reject) => {
                try {
                    this.loading = true;
                    let usuarioService = new UsuarioService(
                        Vue.http,
                        this.$store
                    );
                    usuarioService.listarUsuarios().then(
                        response => {
                            this.loading = false;
                            if (response.status == 200) {
                                this.usuarios = response.body;
                            }
                        },
                        error => {
                            this.loading = false;
                            console.log(error);
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        closeDialog() {
            this.dialog = false;
        },
        closeDialogEditar() {
            this.dialogEditar = false;
        },
        closeDialogUsuario() {
            this.dialogEditar = false;
        },
        buscarUsuarios() {
            return new Promise((resolve, reject) => {
                this.loadingFiltros = true;
                this.loading = true;

                try {
                    let service = new UsuarioService(Vue.http, this.$store);

                    service
                        .filtrarOfertas(
                            this.ultimoFiltro,
                            this.pagina,
                            this.registros_pagina
                        )
                        .then(
                            response => {
                                this.usuarios = [];
                                var usuarios = response.data.results;
                                usuarios.forEach(element => {
                                    if (element.data_inicio)
                                        element.data_inicio = Formatter.formatData(
                                            element.data_inicio.split('T')[0]
                                        );
                                    if (element.data_fim)
                                        element.data_fim = Formatter.formatData(
                                            element.data_fim.split('T')[0]
                                        );
                                    this.usuarios.push(element);
                                });
                                this.loadingFiltros = false;
                                this.loading = false;
                            },
                            error => {
                                this.loadingFiltros = false;
                                this.loading = false;
                                this.mensagem = error;
                                console.log(error);
                            }
                        );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        getBolean(valor) {
            if (valor) {
                return 'done';
            } else {
                return 'close';
            }
        },
        criarUsuario() {
            this.usuario = new Usuario();
            this.usuario.st_ativo = true;
            this.usuario.is_lite_readonly = false;
            this.isCriarUsuario = true;
            this.dialog = true;
            this.dialogLoading = false;
        },
        exibirHistorico(usuario) {
            this.dialogLoading = true;
            let usuarioService = new UsuarioService(Vue.http, this.$store);
            usuarioService.getHistoricoUsuario(usuario).then(
                response => {
                    this.usuarioHistorico = usuario;
                    this.usuarioHistoricos = response.body;
                    this.dialogLoading = false;
                    this.mostrarHistorico = true;
                },
                error => {
                    const mensagem = error.body.erro;
                    this.dialogLoading = false;
                    this.exibirSnackbarError(mensagem);
                }
            );
        },
        exibirDetalhe(usuario) {
            this.usuario = new Usuario();
            this.isCriarUsuario = false;
            this.dialogLoading = true;
            let usuarioService = new UsuarioService(Vue.http, this.$store);
            usuarioService.editarUsuario(usuario).then(
                response => {
                    this.usuario = response.body;
                    this.dialogEditar = true;
                    this.dialogLoading = false;
                },
                error => {
                    this.mensagem = error;
                    this.dialogLoading = false;
                    console.log(error);
                }
            );
        },
        exibirSnackbarError(mensagem) {
            this.mensagem = mensagem;
            this.tipoMensagem = 'error';
            this.snackbarColor = 'red white--text';
            this.snackbar = true;
        },
        verificaUsuario() {
            this.loadingButtonModal = true;
            if (this.isInvalid) {
                this.validarForm = true;
                this.loadingButtonModal = false;
            } else {
                if (this.isCriarUsuario) {
                    if (this.usuario.password && this.usuario.password !== '') {
                        this.salvarUsuario();
                    } else {
                        this.mostrarConfirmacao = true;
                    }
                } else {
                    this.salvarUsuario();
                }
            }
        },
        salvarUsuario: function() {
            let Usuario = new UsuarioService(Vue.http, this.$store);
            this.mostrarConfirmacao = false;
            let data = cloneDeep(this.usuario);
            if (data.password == '') {
                data.password = null;
            }
            data.st_ativo = data.st_ativo ? 1 : 0;
            if (data.tp_usuario !== 'lite') data.is_lite_readonly = false;
            data.is_lite_readonly = data.is_lite_readonly ? 1 : 0;
            Usuario.salvarUsuario(data).then(
                response => {
                    let mensagemPadrao;
                    if (response.status == 201) {
                        mensagemPadrao = 'Usuário Criado com sucesso';
                    } else if (response.status == 200) {
                        mensagemPadrao = 'Usuário Alterado com Sucesso';
                    }

                    this.mensagem = this.getMensagemRequest(
                        response.body,
                        mensagemPadrao
                    );

                    this.tipoMensagem = 'success';
                    this.dialog = false;
                    this.dialogEditar = false;
                    this.snackbar = true;
                    this.snackbarColor = 'green white--text';
                    this.loadingButtonModal = false;
                    this.listarUsuarios();
                },
                error => {
                    this.mensagem = this.getMensagemRequest(
                        error.body,
                        'Erro ao criar Usuário'
                    );
                    this.tipoMensagem = 'error';
                    this.snackbarColor = 'red white--text';
                    this.snackbar = true;
                    this.loadingButtonModal = false;
                }
            );
        }
    },
    watch: {
        breakpoint: {
            deep: true,
            handler(val) {
                this.isExtraSmall = false;
                this.isSmall = false;
                this.isMedium = false;
                if (val.xs) {
                    this.isExtraSmall = true;
                } else if (val.sm) {
                    this.isSmall = true;
                } else if (val.md) {
                    this.isMedium = true;
                }
            }
        }
    },
    computed: {
        breakpoint() {
            return this.$vuetify.breakpoint;
        },
        dynamicHeaders() {
            let headers = cloneDeep(this.headers);
            if (!this.superUsuario) {
                headers = headers.filter(
                    header => header.value !== 'historico'
                );
            }
            return headers;
        }
    },
    mounted() {
        this.listarUsuarios();
        if (this.$store.getters.isPartner == 1) {
            this.primaryColorFont = '#757575';
        } else {
            this.primaryColorFont = this.$vuetify.theme.themes.light.primary;
        }
        if (this.$store.getters.getSuperUser == 1) {
            this.superUsuario = true;
        } else {
            this.superUsuario = false;
        }
    }
};
</script>
